html, body, #root, #root>div {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-size: 0.8125rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
a {
  color: inherit;
  // &[target="_blank"] {
  //   &:after {
  //     content: 'new tab';
  //   }
  // }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
